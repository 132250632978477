.miniatura-blog {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;
	& .miniatura-blog-img{
		border-radius: .1rem .1rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 60%;
		background-color: $gray1;
		position: relative;
		user-select: none;

		& img{
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: 0 .5rem;
		@include border-box;
		&:hover{
			color: $gray1 !important;
		}

		& .left {
			color: $gray1;
			font-size: 1.2rem;
		}
		& .right {
			color: $gray1;
			font-size: 1rem;
			letter-spacing: -.05rem;
    		font-weight: 300;
		}
	}

	& .miniatura-blog-detalhe{
		display: block;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		position: relative;
		border-left: solid 1px $gray4;
		border-bottom: solid 1px $gray4;
		border-right: solid 1px $gray4;
		border-radius: .1rem;
		&:hover{
			color: $gray1 !important;
		}

		& .miniatura-titulo{
			display: block;
			width: 100%;
			& p{
				font-size: 1.1rem;
				font-weight: 600;
				color: $colorTerciary;
			}
		}

		& .miniatura-blog-descricao{
			display: block;
			width: 100%;
			overflow: hidden;
			max-height: 4.5rem;
			height: 4.5rem;
			& p{
				color: $gray3;
				font-size: .9rem;
				color: $gray3;
			}

		}

		& .miniatura-btn-vermais{
			position: absolute;
			right: 5%;
			top: -1rem;
			transform: translate(5%, -1rem);
			& p{
				background-color: $colorPrimary;
				border-radius: .1rem;
				font-size: .9rem;
				font-weight: 500;
				color: $colorTerciary;
				padding: .4rem 1rem;
				&:hover{
					background-color: darken($colorPrimary, 2);
				}
			}
		}
	}

}

/* SÓ SE APLICA NO PC */
@media (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}
}

