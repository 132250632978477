.header-container{
	position: sticky;
	top: 0;
	z-index: 9999;
}


.header1{
	width: 100%;
	background-color: transparent;
}

.header1_2{
	background-color: white;
	width: 100%;
	
	& p{
		color: $gray1 !important;
	}

	& i{
		color: $colorPrimary !important;
	}

	& button{
		color: $colorPrimary !important;
		& i{
			color: $colorTerciary !important;
		}
	}

	& .central{
		& a{
			& p{
				color: white !important;
				background-color: $colorPrimary !important;
				transition: all .2s ease-in-out;
				
				& i{
					color: white !important;
					transition: all .2s ease-in-out;
				}
			}

			&:hover{
				& p{
					background-color: darken($colorPrimary, 5) !important;
				}
			}
		}
	}

	.headerpc {
		& .superior {
			& .sessions {
				& ul {
					& .itens_header2{
						& .itemsession{
							& .title_is{
								& .itemsession_title{
									& i{
										color: $colorTerciary !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.box_header{
	z-index: 999;
	position: relative;
	box-shadow: 0 0 10px 0 rgba($gray1, .1);
}

#sub_menu_vendas{
	display: none;
	width: 230px;
	max-width: 230px;
	max-height: 300px;
	overflow: auto;
	position: absolute;
	left: 50%;
	top: 2.5rem;
	transform: translate(-50%);
	background-color: white;
	border-radius: $borderRadius;
	border: solid 1px lighten($gray4, 10);
	z-index: 3;

	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 20rem;
		padding: .5rem;

		& p{
			font-size: .8rem !important;
			font-weight: 400;
			line-height: normal;
			background-color: none;
			padding: 0.5rem;
			margin: 0;
			text-align: left;
			transition: all .2s ease-in-out;
			color: $gray1;
			border-left: solid 1px transparent;

			&:hover{
				background-color: $gray5;
				padding-left: 1rem;
				border-color: $colorTerciary;
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;
	padding: 1rem 0;

	& .superior {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 10fr .5fr;
		gap: 1rem;

		/* GRUPO HEADER */
		& .logo {
			width: 100%;
			& .img{
				width: calc(500px / 3);
				height: auto;
				display: block;
				
				& img {
					display: block;
					user-select: none;
					width: calc(500px / 3);
					height: auto;
				}
			}
		}

		& .sessions {
			width: 100%;
			& ul {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-around;
				margin: 0%;
				padding: 0%;
				list-style: none;
				transition: all .25s ease;

				& .itens_header2{
					& .itemsession{
						position: relative;
						display: block;
						width: 100%;
	
						& .title_is{
							width: 100%;
							display: block;
							padding: .5rem 0;
	
							& .itemsession_title{
								display: block;
								width: 100%;
								font-size: .85rem;
								color: white;
								text-align: center;
								text-transform: initial;
								font-weight: 400;
								padding: 0;
								@include border-box;
								transition: all .2s ease-in-out;
								line-height: 1rem;
								
								& i{
									vertical-align: middle;
									display: inline-block;
									font-size: .65rem;
									color:  $colorTerciary;
									font-weight: 300;
								}
							}
						}
	
						&:hover{
							#sub_menu_vendas{
								display: initial;
							}
						}
					}
				}

				& .itens_header{
					& a{
						display: inline-block;
						vertical-align: middle;
						& p{
							color: white;
							font-weight: 400;
							font-size: .85rem;
							margin: 0;
							padding: 0;
							& i{
								color: white;
								font-size: 1.2rem;
								vertical-align: middle;
								padding: 0 .2rem;
								margin: 0;
							}
						}
					}

					& p{
						color: white;
						user-select: none;
						font-weight: 400;
						font-size: .85rem;
						margin: 0;
						padding: 0;
						transition: all .15s ease-in-out;
					}
				}

				& .whatsapp_header{
					display: flex;
					align-items: center;
					gap: 1rem;
					a{
						p{
							display: flex;
							align-items: center;
						}
					}
				}

				& .central{
					width: 100%;

					& a{
						& p{
							background-color: $colorPrimary;
							color: white;
							padding: .5rem 1rem;
							border-radius: $borderRadius;
							font-size: .85rem;
							font-weight: 600;
							transition: all .2s ease-in-out;
							&:hover{
								background-color: darken($colorPrimary, 2);
							}
						}
					}
				}
			}
		}

		& .menu {
			width: 100%;
			text-align: right;
		}
	}
}

.btn_menu_header{
	background-color: transparent !important;
	color: $colorPrimary !important;
	border: none !important;
}

.headerindex {
	height: calc(100vh - 7.7rem);
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: 100%;
		width: 100%;

		& .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to bottom, rgba(black, .55), rgba(black, .2), transparent);
			z-index: 2;
		}

		& .bg-headerpc{
			display: block;
			height: 100%;
			width: 100%;
			z-index: 2;
		 	background-image: url('/jnh/header/bg_header6.webp');
		}
	}
}

.header-mob{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .5rem 0;

	& .log{
		width: 100%;
		margin: 0;
		width: calc(500px / 5.5);
		height: auto;
		display: block;
		
		& img {
			display: block;
			user-select: none;
			width: calc(500px / 5.5);
			height: auto;
		}
	}
}


/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media (min-width: 1367px) and (max-width: 1440px){

	.headerpc {
		& .superior {
			grid-template-columns: 1fr 10fr .3fr;

			& .logo {
				& .img{
					width: calc(500px / 4);
					height: auto;
					
					& img {
						width: calc(500px / 4);
						height: auto;
					}
				}
			}
		}
	}

	.headerindex {
		height: calc(100vh - 6.3rem);

		& .container-bg-headerpc{
			& .bg-headerpc{
				background-position: center top 35%;
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){

	.headerpc {
		& .superior {
			grid-template-columns: 1fr 10fr .3fr;

			& .logo {
				& .img{
					width: calc(500px / 3.75);
					height: auto;
					
					& img {
						width: calc(500px / 3.75);
						height: auto;
					}
				}
			}
		}
	}

	.headerindex {
		height: calc(100vh - 6.7rem);

		& .container-bg-headerpc{
			& .bg-headerpc{
				background-position: center top 35%;
			}
		}
	}
}

@media (max-width: 1023px){
	
	.headerindex {
		height: calc(100vh - 4.3rem);
		& .container-bg-headerpc{
			& .bg-headerpc{
				background-position: center right 45%;
			}
		}
	}
}
