.title_condomi{
	display: block;
	width: 100%;
	
	& h2{
		text-align: center;
		font-size: 1.75rem;
		font-weight: 600;
		color: $colorPrimary;
		margin: 0;
		padding-bottom: .5rem;
	}

	& .under_p{
		text-align: center;
		width: 10%;
		height: 1px;
		margin: auto;
		background-color: $colorTerciary;
		border-radius: 2rem;
	}
}

.title_condomi2{
	display: block;	
	width: 100%;

	& h2{
		font-size: 1.75rem;
		font-weight: 600;
		color: $colorPrimary;
		text-align: center;
		padding-bottom: .5rem;
		margin: 0;
	}

	& .under_p{
		text-align: center;
		width: 10%;
		height: 1px;
		margin: auto;
		background-color: $colorTerciary;
		border-radius: 2rem;
	}
}

/* .grit {
	-webkit-mask-image: url("https://ucarecdn.com/9514f9b1-3bf9-4b7c-b31d-9fb8cd6af8bf/");
	mask-image: url("https://ucarecdn.com/9514f9b1-3bf9-4b7c-b31d-9fb8cd6af8bf/");
} */

.section_infraestruturas {
	background: linear-gradient(to bottom, white 9%, $gray4 9%);
	padding: 2rem 0;
	width: 100%;
	background-image: url('/jnh/header/bg_header2.webp');
	background-repeat: no-repeat;
	background-size: 100% 90%;
	background-position: bottom;

	& .title_section_infras {
		background-color: white;
		box-shadow: 0 0 10px 0 rgba($gray1, .3);
		padding: 2.5rem 5rem;
		width: fit-content;
		margin: auto;
		border-radius: $borderRadius;

		& .title{
			text-align: center;
			display: block;
			width: 100%;
			
			& h2{
				font-size: 1.75rem;
				font-weight: 600;
				color: $colorPrimary;
				margin: 0;
				padding-bottom: .5rem;
			}
		
			& .under_p{
				text-align: center;
				width: 30%;
				height: 1px;
				margin: auto;
				background-color: $colorTerciary;
				border-radius: 2rem;
			}
		}
	}
	
	& .container_infras {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;
		width: 100%;
		
		& a{
			& .miniatura_infraestruturas {
				width: 100%;
				position: relative;
				border-radius: $borderRadius;
	
				& .img{
					width: 100%;
					display: block;
					padding-top: 60%;
					background-color: transparent;
					overflow: hidden;
					position: relative;
					border-radius: $borderRadius;
					box-shadow: 0 0 10px 2px rgba($gray2, .3);
					
					& img {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						filter: brightness(80%);
						transition: all .2s ease-in-out;
						user-select: none;
						-webkit-user-drag: none;
						object-fit: cover;
					}
					
				}
				
				& .texto_infraestruturas{
					display: block;
					width: fit-content;
					position: absolute;
					top: 90%;
					left: 50%;
					transform: translate(-50%, -90%);
					pointer-events: none;
					background-color: white;
					border: solid 1px gray;
					border-radius: $borderRadius;
					padding: .5rem 2rem;
					font-weight: 600;
					color: $colorPrimary;
					font-size: 1.1rem;
					z-index: 1;
					line-height: none;
					text-align: center;
					white-space: nowrap;
					transition: border-radius .2s ease-in-out;
				}
			}

			&:hover, &:focus{
				& .miniatura_infraestruturas {
					& .img {
						& img {
							filter: brightness(100%);
							transform: translate(-50%, -50%) scale(1.05);
						}
					}
				}

				& .texto_infraestruturas {
					border-radius: .5rem;
				}
			}
		}
	}
}

.section_servicos{
	width: 100%;
	display: block;
	background: white;
	
	& .container_servicos{
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;

		& a{
			width: 100%;
			padding: 1rem;
			border-radius: .3rem;
			transition: border-color .2s ease-in-out;
			border: solid 1px $gray5;
			box-shadow: 0 0 10px 1px rgba($gray1, .1);
			background-color: white;
	
			& .bloco_servico{
				width: 100%;
				display: block;
				text-align: left;
				

				& .img{
					width: 50px;
					height: 50px;
					display: block;
					margin: 0;

					& img{
						filter: invert(45%) sepia(62%) saturate(2045%) hue-rotate(340deg) brightness(102%) contrast(101%);
						text-align: left;
						width: 50px;
						height: 50px;
					}
				}
	
				& .descricao{
					width: block;
					display: block;
					position: relative;
	
					& h2{
						color: $colorPrimary;
						font-size: .9rem;
						font-weight: 600;
						text-align: left;
						line-height: normal;
					}
	
					& p{
						padding: 0;
						color: lighten($gray2, 5);
						font-size: .9rem;
						font-weight: 400;
						text-align: left;	
						transition: all .15s;
					}
				}
			}
	
			&:hover{
				border-color: lighten($gray4, 10);
			}
		}
	}
}

.section_sobre {
	background: linear-gradient(135deg, white 65%, $colorPrimary 65%);
	background-color: white;
	display: block;
	width: 100%;

	& .container_sobre {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		gap: 2rem;
		align-items: center;

		& .conteudo {
			width: 80%;
			margin: 0 auto;

			& h3 {
				font-size: 1.3rem;
				font-weight: 600;
				color: $colorTerciary;
				margin: 0;
			}

			& h2 {
				font-size: 1.5rem;
				font-weight: 600;
				color: $colorPrimary;
			}

			& p {
				font-size: 1rem;
				font-weight: 400;
				color: $gray2;
			}

			& .btn_vermais {
				width: 100%;
				margin: 0;
				
				& a {
					width: fit-content;
					display: block;
					margin: 0;

					& p {
						background-color: $colorPrimary;
						color: white;
						font-size: 1rem;
						font-weight: 600;
						padding: .3rem 1.5rem;
						border-radius: $borderRadius;
						transition: background-color .2s ease-in-out;

						& i {
							color: #FFFFFF !important;
							font-size: 1.2rem;
							vertical-align: middle;
						}

						&:hover {
							background-color: darken($colorTerciary, 2);
						}

					}
				}
			}

			& .infos_sobre {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 2rem 3rem;

				& .info_imob{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;

					& .img{
						width: 55px;
						height: 50px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;

						& img{
							width: 100%;
						}

						i{
							color: white !important;
							display: flex;
							justify-content: center;
							text-align: center;
							display: none;
						}

						.fa-solid .fa-location-dot{
							flex-basis: 100px;
						}
					}
			
					& .descricao{
						width: 100%;
			
						& .title{
							margin: 0;
							font-size: .9rem;
							color: $colorPrimary;
							font-weight: 600;
							margin: 0;
						}
						
						& p{
							padding-left: 1rem;
							color: $gray2;
							font-size: .9rem;
							font-weight: 400;
							margin: 0;
						}
			
						& a{
							width: fit-content;
							display: block;
							margin: 0;

							& p{
								color: $gray2;
								font-size: .9rem;
								font-weight: 400;
								margin: 0;
								background-color: transparent;
							}
						}
					}
				}
			}

		}

		& .imagem_sobre {
			width: 100%;
			margin: 0;
			display: flex;
			justify-content: center;

			& img {
				border-radius: $borderRadius;
				width: calc(900px / 2);
				height: auto;
				-webkit-user-drag: none;
			}
		}
	}
}

.bg_busca_interna{
	background-color: white;
	display: block;
	width: 100%;

	& h2{
		text-align: center;
		color: $gray1;
		font-size: 1.8rem;
		font-weight: 400;
	}


	& .container_filtros_busca_interna{
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 2rem 1rem;

		& .grid_items{
			width: 100%;

			& .titulo-formulario{
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			& .grid_botoes_buscar_limpar{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: .5rem;
			}
		}
	}
}

#ux_to_top{
	position: relative;
	top: -100px;
	left: -90%;
	z-index: 999999;
}

.botao_voltar{
	background-color: white;
	border: solid 1px lighten($gray4, 10);
	border-radius: $borderRadius;
	padding: .3rem 1rem;
	color: $gray1 !important;
	font-size: .9rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	& i {
		color: $colorTerciary;
	}

	&:hover{
		border-color: $colorTerciary;
	}
}

.title_contato{
	font-size: 1.5rem;
	font-weight: 600;
	color: $colorPrimary;
	vertical-align: middle;
	& i{
		color: $colorTerciary;
	}
}

.width_vid{
	width: 50vw !important;
}

@media (min-width: 1024px) and (max-width: 1366px){

	.section_infraestruturas {
		background-size: 100% 89%;

		& .title_section_infras {
			padding: 2rem 5rem;
	
			& .title{
				& h2{
					font-size: 1.6rem;
				}
			}
		}
	}

	.section_sobre {
		& .container_sobre {
			gap: 1rem;
	
			& .conteudo {
				width: 85%;
	
				& p {
					font-size: .9rem;
				}
	
				& strong {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;
							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					gap: 2rem;
	
					& .info_imob{
				
						& .img{
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);
				
							& img{
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}
				
						& .descricao{
							& p{
								font-size: .85rem;
							}
				
							& a{
								& p{
									font-size: .85rem;
								}
							}
						}
					}
				}
			}
	
			& .imagem_sobre {
				& img {
					width: calc(900px / 2.25);
					height: auto;
				}
			}
		}
	}
}


@media (max-width: 1023px){
		
	.width_vid{
		width: 80vw !important;
	}

	.title_condomi{
		& h2{
			font-size: 1.5rem;
		}

		& .under_p{
			width: 30%;
		}
	}

	.title_condomi2{
		& h2{
			font-size: 1.5rem;
		}

		& .under_p{
			width: 30%;
		}
	}

	.bg_busca_interna{
		& h2{
			font-size: 1.4rem;
		}
	
		& .container_filtros_busca_interna{
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;
		}
	}

	.section_infraestruturas {
		background: linear-gradient(to bottom, white 4%, $colorPrimary 4%);
		padding: 2rem 0;
		width: 100%;

		& .title_section_infras {
			padding: 1.5rem;
			width: 90%;
			margin: auto;
	
			& .title{
				& h2{
					font-size: 1.2rem;
				}
			}
		}
		
		
		& .container_infras{
			grid-template-columns: 1fr;
			padding: 0;
			
			& a {
				& .miniatura_infraestruturas {
					& .texto_infraestruturas{
						padding: .2rem .5rem;
						font-size: 1rem;
					}
				}
			}
		}
	}

	.section_sobre {
		background: linear-gradient(180deg, $colorPrimary 20%, white 20%);
		
		& .container_sobre {
			display: flex;
			flex-direction: column;
			flex-direction: column-reverse;
			gap: 1rem;
			grid-template-columns: none;
			

			& .conteudo {
				width: 100%;

				& h3 {
					font-size: 1.2rem;
				}
	
				& h2 {
					font-size: 1.3rem;
				}
	
				& p {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;
							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					grid-template-columns: 1fr;
					gap: 2rem;
	
					& .info_imob{
				
						& .img{
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);
				
							& img{
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}
				
						& .descricao{
							& p{
								font-size: .85rem;
							}
				
							& a{
								& p{
									font-size: .85rem;
								}
							}
						}
					}
				}
			}
	
			& .imagem_sobre {
				text-align: center;
				& img {
					width: calc(900px / 3);
					height: auto;
					max-width: 90vw;
					margin: auto;
				}
			}
		}
	}


	.section_servicos{
		& .container_servicos{
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;

			& a{
				& .bloco_servico{
					& .descricao{
						& h2{
							font-size: .9rem;
						}
					}
				}
			}
		}
	}

	#ux_to_top{
		left: 15%;
		top: -80px;
	}
}