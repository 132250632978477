.miniatura-imovel {
	display: flex;
	flex-direction: column;
	width: 100%;

	& .miniatura-imovel-img{
		border-radius: $borderRadius $borderRadius 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 80%;
		background-color: $gray5;
		position: relative;
		user-select: none;
		border: solid 1px lighten($gray4, 10);
		border-bottom: none;
		transition: all .2s ease-in-out;

		& .imo_vendido_alugado_tarja{
			position: absolute;
			width: 100%;
			padding: 5rem 0;
			background-color: rgba(black, .5);
			z-index: 9;
			inset: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			& p{
				color: white;
				font-size: .9rem;
				font-weight: 400;
				text-align: center;

				& span{
					line-height: 2rem;
					background-color: $colorPrimary;
					font-weight: 600;
					padding: .2rem 1rem;
					border-radius: .2rem;
				}
			}
		}

		& img{
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
		}

		& .miniatura-imv-status{
			top: .5rem;
			left: .5rem;
			position: absolute;
			& p{
				background-color: white;
				border-radius: $borderRadius;
				font-size: .8rem;
				font-weight: 600;
				line-height: normal;
				text-align: center;
				color: $gray1;
				padding: .2rem .5rem;
				margin: 0
			}
		}

		& .miniatura-cod{
			position: absolute;
			top: .5rem;
			right: .5rem;
			& p{
				background-color: white;
				border-radius: $borderRadius;
				font-size: .8rem;
				font-weight: 600;
				line-height: normal;
				text-align: center;
				color: $gray1;
				padding: .2rem .5rem;
				margin: 0
			}
		}
	}

	& .miniatura-imovel-detalhe{
		display: flex;
		flex-direction: column;
		border: solid 1px lighten($gray4, 10);
		border-top: none;
		border-radius: 0 0 $borderRadius $borderRadius;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		transition: all .2s ease-in-out;

		& .miniatura-localizacao {
			width: 100%;

			& p{
				font-size: 1rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;
			}
		}

		& .miniatura-acao-tipo {
			width: 100%;
			
			& p{
				text-transform: initial;
				font-size: .85rem;
				font-weight: 400;
				color: $gray2;
			}
		}

		& .miniatura-imovel-btns-icons{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: .5rem;
			height: 5rem;
			width: 100%;

			& ul{
				& li{
					font-size: .8rem;
					color: $gray2;
					font-weight: 400;

					& i{
						color: $colorPrimary;
						font-weight: 400;
						font-size: 1rem;
					}
				}
			}
		}
		
		& .miniatura-preco{
			display: block;
			width: 100%;

			& p{
				width: 100%;
				padding-top: 1rem;
				text-align: left;
				font-size: 1.2rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;
			}
		}
	}

	&:hover{
		& .miniatura-imovel-img{
			border-color: darken($gray4, 5);
		}

		& .miniatura-imovel-detalhe{
			border-color: darken($gray4, 5);
		}
	}
}

/* SÓ SE APLICA NO PC */
@media (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}

}

@media (min-width: 1024px) and (max-width: 1366px){
	.miniatura-imovel {
		& .miniatura-imovel-detalhe{
			& .miniatura-preco{
				& .aca_tip{
					margin-top: 1rem;
				}
			}
		}
	}
}

